
import { Options, Vue } from 'vue-class-component';
@Options({
  components: {}
})
export default class Pay extends Vue {
  text = '未知错误';

  created () {
    const { message } = this.$route.query
    if (message) {
      this.text = message.toString()
    }
  }
}
